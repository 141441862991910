<template>
  <div id="main">
    <MainMenu></MainMenu>
  </div>
</template>

<script>
import MainMenu from "@/components/Menu/Menu.vue";
export default {
  components: {
    MainMenu,
  },
};
</script>
